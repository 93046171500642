import {
  validateEmail,
  validateRequired,
  Validator,
} from 'application/utils/validators';

// eslint-disable-next-line max-len
export const SUBMIT_FORM_SEC_CALL_BACK = '[form] submit 1st step sec call back';
export type SubmitForm = {
  type: typeof SUBMIT_FORM_SEC_CALL_BACK;
}
export const submitForm = {
  type: SUBMIT_FORM_SEC_CALL_BACK,
};

export const RESET_FORM_SEC_CALL_BACK = '[sec-callback] form reset';
export type ResetForm = {
  type: typeof RESET_FORM_SEC_CALL_BACK,
};

export const resetForm: ResetForm = {
  type: RESET_FORM_SEC_CALL_BACK,
};

// eslint-disable-next-line max-len
export const SET_FORM_LOADING_SEC_CALL_BACK = '[forms] set loading sec call back';
export type SetFormLoading = {
  type: typeof SET_FORM_LOADING_SEC_CALL_BACK;
  payload: {
    isLoading: boolean;
  }
};

export const setFormLoading = (
  isLoading: boolean,
): SetFormLoading => ({
  type: SET_FORM_LOADING_SEC_CALL_BACK,
  payload: {
    isLoading,
  },
});

// eslint-disable-next-line max-len
export const SET_FORM_SUCCESS_SEC_CALL_BACK = '[forms] set success sec call back';
export type SetFormSuccess = {
  type: typeof SET_FORM_SUCCESS_SEC_CALL_BACK;
  payload: {
    isSuccess: boolean;
  }
};

export const setFormSuccess = (
  isSuccess: boolean,
): SetFormSuccess => ({
  type: SET_FORM_SUCCESS_SEC_CALL_BACK,
  payload: {
    isSuccess,
  },
});

// eslint-disable-next-line max-len
export const SET_INPUT_SEC_CALL_BACK = '[forms] input change sec call back';

export type SetInput = {
  type: typeof SET_INPUT_SEC_CALL_BACK;
  payload: {
    fieldName: string;
    value: string;
    validators: Validator[];
  }
}
export type SetInputValidated = {
  type: typeof SET_INPUT_SEC_CALL_BACK;
  payload: {
    [fieldName: string]: {
      value: string;
      error: string;
    }
  }
}

export const setRequiredInput = (fieldName: string) => (
  value: string,
): SetInput => ({
  type: SET_INPUT_SEC_CALL_BACK,
  payload: {
    fieldName,
    value,
    validators: [validateRequired],
  },
});

export const setRequiredEmail = (fieldName: string) => (
  value: string,
): SetInput => ({
  type: SET_INPUT_SEC_CALL_BACK,
  payload: {
    fieldName,
    value,
    validators: [validateRequired, validateEmail],
  },
});

// eslint-disable-next-line max-len
export const SET_PHONE_INPUT_SEC_CALL_BACK = '[forms] phone number change sec call back';
export type SetPhoneInput = {
  type: typeof SET_PHONE_INPUT_SEC_CALL_BACK;
  payload: {
    countryCode: string;
    number: string;
    isRequired: boolean;
  }
}
export type SetPhoneInputValidated = {
  type: typeof SET_PHONE_INPUT_SEC_CALL_BACK;
  payload: {
    phoneNumber: {
      countryCode: string;
      number: string;
      error: string;
    };
  }
}

export const setRequiredPhoneInput = (
  countryCode: string,
  number: string,
): SetPhoneInput => ({
  type: SET_PHONE_INPUT_SEC_CALL_BACK,
  payload: {
    countryCode,
    number,
    isRequired: true,
  },
});

// eslint-disable-next-line max-len
export const SET_GENERAL_ERROR_SEC_CALLBACK = 'set general error sec callback';
export type SetGeneralErrorMessage = {
  type: typeof SET_GENERAL_ERROR_SEC_CALLBACK;
  payload: string;
}

export const setGeneralErrorMessage = (
  message: SetGeneralErrorMessage['payload'],
): SetGeneralErrorMessage => ({
  type: SET_GENERAL_ERROR_SEC_CALLBACK,
  payload: message,
});

// eslint-disable-next-line max-len
export const SHOW_SECOND_STEP_SEC_CALL_BACK = '[forms] show 2nd step sec call back';

export type ShowSecondStep = {
  type: typeof SHOW_SECOND_STEP_SEC_CALL_BACK,
};
export const showSecondStep = {
  type: SHOW_SECOND_STEP_SEC_CALL_BACK,
};

export type SecCallbackActions =
| SetInputValidated
| SetPhoneInputValidated
| SetFormLoading
| SetFormSuccess
| ShowSecondStep
| SetGeneralErrorMessage
| ResetForm;
